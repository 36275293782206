import { Suspense, lazy, useEffect } from 'react'
import {
  Outlet,
  Route,
  RouterProvider,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom'
import Loader from 'components/Loader'
import { wrapCreateBrowserRouter } from '@sentry/react'
import LoginRoute from './LoginRoute'
import { ProtectedLoggedRoute, RouteLayout } from './ProtectedRoute'
import NotFoundRoute from './NotFoundRoute'

//* Páginas únicas
const SignIn = lazy(() => import('pages/SignIn'))
const MyPerfil = lazy(() => import('pages/MyPerfil'))
const Panel = lazy(() => import('pages/Panel'))
const PartnershipLeads = lazy(() => import('pages/PartnershipLeads'))
const LeadAdsForms = lazy(() => import('pages/LeadAdsForms'))
const LeadsSubmission = lazy(() => import('pages/LeadsSubmision'))
const Services = lazy(() => import('pages/Services'))
const Reports = lazy(() => import('pages/Reports'))
const Highlights = lazy(() => import('pages/Highlights'))
const Attributes = lazy(() => import('pages/Attributes'))
const Locations = lazy(() => import('pages/Locations'))
const CustomerFaq = lazy(() => import('pages/CustomerFaq'))
const Institutional = lazy(() => import('pages/Institutional'))
const FinancialInstitutions = lazy(() => import('pages/FinancingFees'))
const SavedSearches = lazy(() => import('pages/SavedSearches'))
const Feeds = lazy(() => import('pages/Feeds'))
const Customization = lazy(() => import('pages/Customization'))
const Vince = lazy(() => import('pages/Vince'))
const Debug = lazy(() => import('pages/Debug'))
const LogsPage = lazy(() => import('pages/Logs'))

//* Páginas de Imóveis
const RealtiesTablePage = lazy(() => import('pages/Realties'))
const RealtiesRegister = lazy(() => import('pages/Realties/register'))
const RealtiesEdit = lazy(() => import('pages/Realties/edit'))
const RealtiesTimeline = lazy(() => import('pages/Realties/timeline'))

//* Páginas de Leads
const Leads = lazy(() => import('pages/Leads'))
const LeadsEdit = lazy(() => import('pages/Leads/edit'))

//* Páginas de Cadastros
const Clients = lazy(() => import('pages/Clients'))
const ClientsRegister = lazy(() => import('pages/Clients/register'))
const ClientsEdit = lazy(() => import('pages/Clients/edit'))
const ClientsProfile = lazy(() => import('pages/Clients/profile'))

//* Páginas de Empresas
const Companies = lazy(() => import('pages/Companies'))
const CompaniesRegister = lazy(() => import('pages/Companies/register'))
const CompaniesEdit = lazy(() => import('pages/Companies/edit'))
const CompaniesContactOptions = lazy(() =>
  import('pages/Companies/contactOptions'),
)
const CompanyTransactions = lazy(() => import('pages/Companies/transactions'))

//* Páginas de Corretores
const Brokers = lazy(() => import('pages/Brokers'))
const BrokersRegister = lazy(() => import('pages/Brokers/register'))
const BrokersEdit = lazy(() => import('pages/Brokers/edit'))
const BrokersProfile = lazy(() => import('pages/Brokers/profile'))
const BrokersContactOptions = lazy(() => import('pages/Brokers/contactOptions'))
const BrokersTransactions = lazy(() => import('pages/Brokers/transactions'))

//* Páginas de Agências
const Agencies = lazy(() => import('pages/MarketingAgency'))
const AgenciesRegister = lazy(() => import('pages/MarketingAgency/register'))
const AgenciesEdit = lazy(() => import('pages/MarketingAgency/edit'))

//* Páginas de CRMs
const CRMsPage = lazy(() => import('pages/CRMs'))
const CRMsRegister = lazy(() => import('pages/CRMs/register'))
const CRMsEdit = lazy(() => import('pages/CRMs/edit'))

//* Páginas de Pessoas
const PersonsTablePage = lazy(() => import('pages/Persons'))
const PersonsRegister = lazy(() => import('pages/Persons/register'))
const PersonsEdit = lazy(() => import('pages/Persons/edit'))

//* Páginas de Campanhas
const Campaigns = lazy(() => import('pages/Campaigns'))
const CampaignsRegister = lazy(() => import('pages/Campaigns/register'))
const CampaignsEdit = lazy(() => import('pages/Campaigns/edit'))
const CampaignPerformance = lazy(() => import('pages/CampaignPerformance'))

//* Páginas da Home do Apto
const HomeApto = lazy(() => import('pages/HomeApto'))
const HomeAptoRegister = lazy(() => import('pages/HomeApto/register'))
const HomeAptoEdit = lazy(() => import('pages/HomeApto/edit'))

//* Páginas de Vagas
const Jobs = lazy(() => import('pages/Jobs'))
const JobsRegister = lazy(() => import('pages/Jobs/register'))
const JobsEdit = lazy(() => import('pages/Jobs/edit'))

//* Páginas de Estação Ferroviaria
const RailStation = lazy(() => import('pages/RailStation'))
const RailStationRegister = lazy(() => import('pages/RailStation/register'))
const RailStationEdit = lazy(() => import('pages/RailStation/edit'))

//* Páginas de Índices Imobiliários
const PropertyIndexes = lazy(() => import('pages/PropertyIndexes'))
const PropertyIndexesEdit = lazy(() => import('pages/PropertyIndexes/edit'))

//* Páginas de Usuários
const Users = lazy(() => import('pages/Users'))
const UserEdit = lazy(() => import('pages/Users/edit'))
const UserRegister = lazy(() => import('pages/Users/register'))

//* Páginas de Cupons
const CuponsPage = lazy(() => import('pages/Coupons'))
const CuponRegister = lazy(() => import('pages/Coupons/register'))
const CouponEdit = lazy(() => import('pages/Coupons/edit'))

const loggedRoutes = [
  { path: 'realties', Page: RealtiesTablePage },
  { path: 'realties/register', Page: RealtiesRegister },
  { path: 'realties/edit/:id', Page: RealtiesEdit },
  { path: 'realties/timeline/:id', Page: RealtiesTimeline },
  { path: 'leads', Page: Leads },
  { path: 'leads/edit/:id', Page: LeadsEdit },
  { path: 'partnership-leads', Page: PartnershipLeads },
  { path: 'clients', Page: Clients },
  { path: 'clients/register', Page: ClientsRegister },
  { path: 'clients/edit/:id', Page: ClientsEdit },
  { path: 'clients/:id', Page: ClientsProfile },
  { path: 'companies', Page: Companies },
  { path: 'companies/edit/:id', Page: CompaniesEdit },
  { path: 'companies/register', Page: CompaniesRegister },
  { path: 'companies/contacts/:id', Page: CompaniesContactOptions },
  { path: 'companies/transactions/:id', Page: CompanyTransactions },
  { path: 'brokers', Page: Brokers },
  { path: 'brokers/register', Page: BrokersRegister },
  { path: 'brokers/edit/:id', Page: BrokersEdit },
  { path: 'brokers/:id', Page: BrokersProfile },
  { path: 'brokers/contacts/:id', Page: BrokersContactOptions },
  { path: 'brokers/transactions/:id', Page: BrokersTransactions },
  { path: 'agencies', Page: Agencies },
  { path: 'agencies/register', Page: AgenciesRegister },
  { path: 'agencies/edit/:id', Page: AgenciesEdit },
  { path: 'crms', Page: CRMsPage },
  { path: 'crms/register', Page: CRMsRegister },
  { path: 'crms/edit/:id', Page: CRMsEdit },
  { path: 'persons', Page: PersonsTablePage },
  { path: 'persons/register', Page: PersonsRegister },
  { path: 'persons/edit/:id', Page: PersonsEdit },
  { path: 'campaigns', Page: Campaigns },
  { path: 'campaigns/register', Page: CampaignsRegister },
  { path: 'campaigns/edit/:id', Page: CampaignsEdit },
  { path: 'campaigns/performance/:id', Page: CampaignPerformance },
  { path: 'lead-ads-forms', Page: LeadAdsForms },
  { path: 'leads-submission', Page: LeadsSubmission },
  { path: 'services', Page: Services },
  { path: 'reports', Page: Reports },
  { path: 'highlights', Page: Highlights },
  { path: 'home-apto', Page: HomeApto },
  { path: 'home-apto/register', Page: HomeAptoRegister },
  { path: 'home-apto/edit/:id', Page: HomeAptoEdit },
  { path: 'attributes', Page: Attributes },
  { path: 'locations', Page: Locations },
  { path: 'jobs', Page: Jobs },
  { path: 'jobs/register', Page: JobsRegister },
  { path: 'jobs/edit/:id', Page: JobsEdit },
  { path: 'rail-station', Page: RailStation },
  { path: 'rail-station/register', Page: RailStationRegister },
  { path: 'rail-station/edit/:id', Page: RailStationEdit },
  { path: 'coupons', Page: CuponsPage },
  { path: 'coupons/register', Page: CuponRegister },
  { path: 'coupons/edit/:id', Page: CouponEdit },
  { path: 'customer-faq', Page: CustomerFaq },
  { path: 'property-indexes', Page: PropertyIndexes },
  { path: 'property-indexes/:id', Page: PropertyIndexesEdit },
  { path: 'institutionals', Page: Institutional },
  { path: 'financing-fees', Page: FinancialInstitutions },
  { path: 'saved-searches', Page: SavedSearches },
  { path: 'feeds', Page: Feeds },
  { path: 'customization', Page: Customization },
  { path: 'vince', Page: Vince },
  { path: 'users', Page: Users },
  { path: 'users/register', Page: UserRegister },
  { path: 'users/edit/:id', Page: UserEdit },
  { path: 'debug', Page: Debug },
  { path: 'logs', Page: LogsPage },
]

const sentryCreateBrowserRouter = wrapCreateBrowserRouter(createBrowserRouter)

const router = sentryCreateBrowserRouter(
  createRoutesFromElements(
    <Route path="/" element={<Outlet />}>
      <Route
        path="login"
        element={
          <LoginRoute>
            <SignIn />
          </LoginRoute>
        }
      />

      <Route element={<RouteLayout />}>
        <Route index element={<Panel />} />

        <Route path="perfil" element={<MyPerfil />} />

        {loggedRoutes.map(({ path, Page }, index) => (
          <Route
            key={index}
            path={path}
            element={
              <ProtectedLoggedRoute path={path}>
                <Page />
              </ProtectedLoggedRoute>
            }
          />
        ))}

        <Route path="/*" element={<NotFoundRoute />} />
      </Route>
    </Route>,
  ),
)

const Router = () => {
  useEffect(() => {
    function throwError(e) {
      const pathname = window.location.pathname

      if (
        pathname.includes('register') ||
        pathname.includes('edit') ||
        pathname === '/customization' ||
        pathname === '/vince'
      ) {
        var confirmationMessage =
          'Todas as alterações não salvas serão perdidas ' + 'Deseja continuar?'

        ;(e || window.event).returnValue = confirmationMessage //Gecko + IE
        return confirmationMessage //Gecko + Webkit, Safari, Chrome etc.
      }
    }

    window.addEventListener('beforeunload', throwError)

    return () => {
      window.removeEventListener('beforeunload', throwError)
    }
  }, [])

  return (
    <Suspense fallback={<Loader />}>
      <RouterProvider router={router} />
    </Suspense>
  )
}

export default Router
